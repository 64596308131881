<template>
    <div>
        <div class="header-moblie"> 
            
            <el-menu default-active="1-4-1" class="el-menu-vertical-demo menu-nav-mobile" v-if="showmenu" >
                <el-submenu index="1" v-if="shoopingMenu.children && shoopingMenu.children.length > 0">
                    <template slot="title">
                        <span slot="title">PARTS DIAGRAMS</span>
                    </template> 
                
                    <el-submenu :index="i+'hj'" v-for="(v,i) in shoopingMenu.children" :key="v.id">
                        <span slot="title">{{v.title}}</span>
                        <el-menu-item  :index="i3+'aad'" v-for="(v3,i3) in v.children"  :key="v3.id" @click="toshopping(v3.url)">
                                    <span slot="title">{{v3.title}}</span>
                        </el-menu-item >
                    </el-submenu>
                </el-submenu>

               



                <template v-for="(vs,is) in menuList"  v-if="menuList.length>0">
                    <el-submenu :index="is+'v'" v-if="vs.content.length>0">
                        <span slot="title">{{vs.title}}</span>
                        <el-menu-item v-for="(v1,i1) in vs.content" :key="v1.id" @click="toshopping('/#'+v1.url)">
                            <span slot="title">{{v1.title}}</span>
                        </el-menu-item>


                    </el-submenu>
                    
                    <el-menu-item :index="is+'vv'" v-if="vs.content.length==0">
                            <span slot="title">{{vs.title}}</span>
                    </el-menu-item>
                </template>
                


                <span class="showless" @click="showmenu=false">Show Less</span>

            </el-menu>


            <div class="icon_s">
                <img src="./images/header-logo-img.png" width="100">
                
                <i class="el-icon-s-home" v-if="0"  @click="$router.push('/home')"></i>
                <!-- <i class="el-icon-s-goods"></i> -->
                <i class="el-icon-user-solid" v-if="0" @click="user_account"></i>
                <div class="right_icons">
                    <i class="el-icon-s-fold" @click="nav_show"></i>
                    <div class="item" @click="carshow" >
                            <div class="item1">
                               <span class="carlist_length">
                                {{carlist.length }} 
                               </span>
                                <div class="tag">
                                    <img src="@/assets/imgs/car.png" alt="">
                                    <!-- <span>{{ carnum }}</span> -->
                                </div>
                            </div>
                    </div>
                </div>
                
                
            </div>

            <div class="cardlog" :class="carmask ? 'show' : ''">
                <div @click="carmask = false" class="close"><i class="el-icon-close"></i></div>
                <ul>
                    <li v-for="(v, i) in carlist" :key="i">
                        <div class="icon"><i @click="deleteItem(v.product_id, v.key)" class="el-icon-close"></i></div>
                        <div class="img"><img :src="v.product_image_url" alt=""></div>
                        <div class="info">
                            <h1 @click="tocarDetail(v.product_url)">{{ v.product_name }}</h1>
                            <h2>{{money_number}}{{ v.price }}</h2>
                        </div>
                        <div class="number">Quantity: {{ v.quantity }}
                        </div>
                    </li>
                </ul>
                <div class="total">
                    <div class="left">Subtotal:</div>
                    <div class="right">{{money_number}}{{ price.toFixed(2) }}
                    </div>
                </div>
                <div class="btn" v-if="carlist && carlist.length > 0">
                    <el-button @click="viewCart" style="background: #8b8b8b; color: #fff; font-size: 12px;line-height: 21px;">View Cart</el-button>
                    <el-button @click="Checkout"
                        style="background: #ed3333; color: #fff; font-size: 12px;line-height: 21px;">Checkout</el-button>
                </div>
            </div>
       </div>
        <div class="header">
            <div class="cardlog" :class="carmask ? 'show' : ''">
                <div @click="carmask = false" class="close"><i class="el-icon-close"></i></div>
                <ul>
                    <li v-for="(v, i) in carlist" :key="i">
                        <div class="icon"><i @click="deleteItem(v.product_id, v.key)" class="el-icon-close"></i></div>
                        <div class="img"><img :src="v.product_image_url" alt=""></div>
                        <div class="info">
                            <h1 @click="tocarDetail(v.product_url)">{{ v.product_name }}</h1>
                            <h2>${{ v.price }}</h2>
                        </div>
                        <div class="number">Quantity: {{ v.quantity }}
                        </div>
                    </li>
                </ul>
                <div class="total">
                    <div class="left">Subtotal:</div>
                    <div class="right">${{ price.toFixed(2) }}
                    </div>
                </div>
                <div class="btn" v-if="carlist && carlist.length > 0">
                    <el-button @click="viewCart" style="background: #8b8b8b; color: #fff; font-size: 1.1458vw;">View
                        cart</el-button>
                    <el-button @click="Checkout"
                        style="background: #ed3333; color: #fff; font-size: 1.1458vw;">Checkout</el-button>
                </div>
            </div>
            <div class="top">
                <a v-bind:href="shopUrl.url"><img class="logo" src="@/assets/imgs/logo.png" alt=""></a>
                <!-- <div class="serch">
                    <el-input class="one" placeholder="VIN#" suffix-icon="el-icon-search" v-model="Model"></el-input>
                    <el-select class="two" v-model="value" placeholder="Please Select">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                    <el-button @click="vinLink">SEARCH</el-button>
                </div> -->

                <div class="serchs">
        
                    <el-select
                        
                        style=" width: 81%;margin-right: 20px;height:2.4896vw"
                        :filter-method="getvin"
                        :value="value"
                        filterable
                        placeholder="Search Parts, Part No. or Product#"
                        :loading="isLoading"
                    >
                        <el-option
                        v-for="item in vinoption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        @click.native="navigateToLink(item)"
                        
                        >
                            {{item.sku}}
                            &nbsp;&nbsp;<span class="red_tips" v-if="item.label">|</span>&nbsp;&nbsp;{{item.label}}
                            &nbsp;&nbsp;<span class="red_tips" v-if="item.model">|</span>&nbsp;&nbsp;{{item.model}}
                            &nbsp;&nbsp;<span class="red_tips" v-if="item.year">|</span>&nbsp;&nbsp;{{item.year}}
                        </el-option>
                    </el-select>


                    <el-button @click="vinLink_search" style="height:2.4896vw">SEARCH</el-button>





                </div>
                


                <div class="items">
                    <div class="item">
                        <div @click="$router.push('/home')">DEALER PORTAL</div>
                    </div>
                    <div class="item" @click="carshow">
                        <div>${{ price.toFixed(2) }} <div class="tag"><img src="@/assets/imgs/car.png" alt=""><span>{{
                            carnum }}</span></div>
                        </div>
                    </div>
                    <div @click="open" class="item account">
                        <div> <img src="@/assets/imgs/user.png" alt=""> MY ACCOUNT <i style="font-size: 1.3542vw;"
                                class="el-icon-caret-bottom"></i></div>
                        <div style="position: absolute;bottom: 0;left: -0.2604vw;">
                            <ul :style="{ height: account.length * 2.0833 + 'vw' }" ref="account">
                                <li @click="accountlink(v.url, v.name)" v-for="(v, i) in account" :key="i">
                                    <img :src="v.white_icon" alt="">
                                    {{ v.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-box">
                <div @click="$router.push('/home')" class="left"><img src="@/assets/imgs/home.png" alt="">DASHBOARD</div>
                <ul class="link-list">
                    <li @click.stop="toshopping(shoopingMenu.url)" @mouseleave="mouseleave(shoopingMenu.gray_icon, $event)"
                        @mouseenter="mouseenter(shoopingMenu.white_icon, $event)">
                        <img :src="shoopingMenu.gray_icon" alt="">
                        <span>{{ shoopingMenu.title }}</span>
                        <i v-if="shoopingMenu.children && shoopingMenu.children.length > 0" class="el-icon-caret-bottom"></i>
                        <ul>
                            <li @click.stop="toshopping(v.url)" v-for="(v, i) in shoopingMenu.children" :key="i">{{ v.title }}
                                <i v-if="shoopingMenu.children && shoopingMenu.children.length > 0"
                                    class="el-icon-caret-right"></i>
                                <ul>
                                    <li @click.stop="toshopping(v.url)" v-for="(v, i) in v.children" :key="i">{{ v.title
                                    }} <i v-if="v.children && v.children.length > 0" class="el-icon-caret-right"></i>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul class="link-list">
                    <li @mouseleave="mouseleave(v.gray_icon, $event)" @mouseenter="mouseenter(v.white_icon, $event)"
                        v-for="(v, i) in menuList" :key="i">
                        <img :src="v.gray_icon" alt="">
                        <router-link :to='v.url'><span>{{ v.title }}</span></router-link>
                        <i v-if="v.content && v.content.length > 0" class="el-icon-caret-bottom"></i>
                        <ul>
                            <li @click="link(v.type, v.url)" v-for="(v, i) in v.content" :key="i">{{ v.title }} <i
                                    v-if="v.content && v.content.length > 0" class="el-icon-caret-bottom"></i></li>
                        </ul>
                    </li>
                </ul>
                <div class="right"></div>
            </div>
        </div>
    </div>
       
</template>

<script>
import { $api_menuList,$api_registerList } from "@/api/index";
import debounce from "@/utils/debounce.js";
import local from "@/utils/local";
import shopUrl from "@/api/url";
export default {
    data() {
        return {
            showmenu:false,
            shopUrl:shopUrl,
            carnum: 0,
            price: 0,
            carmask: false,
            accountList: [],
            shoopingMenu: { title: 'Parts Diagrams',url:'https://www.aodes.com/bundle-product/' },
            activeIndex: '1-4-3',
            Model: '',
            value: null,
            money_number:'$',
            options: [
                {
                    label: 'Inventory',
                    value: '/inventory/records'
                },
                {
                    label: 'Claims',
                    value: '/claim/compensate'
                },
                {
                    label: 'Recalls',
                    value: '1'
                },
                {
                    label: 'Registrations',
                    value: '/warranty/register'
                },
                {
                    label: 'Maintenances',
                    value: '/maintain/service'
                },
            ],
            isopen: false,
            account: [
            ],
            menuList: [],
            carlist: [],
            accessories: [], // 所有配件数据
            vinoption: [],
            value:'',
            isLoading: false,

        }
    },

    


    methods: {
        user_account(){
            window.location.href ='https://www.aodes.com/my-account/edit-account';
        },
    nav_show(){

        if(this.showmenu) this.showmenu=false;
        else this.showmenu=true;
        console.log(this.showmenu)
    },
    isExternalLink(url) {
        return url.includes('distributor');
    },
    navigateToLink(link) {

        window.open(link.link, '_blank');
    },
    formatLabel(item){
        return ` ${item.sku}<span style="color: red;"> | </span>${item.name}`;
    },
    getvin: debounce(function (val) {
      this.isLoading = true;
      let $this = this;
      this.value = val;
      let xmlHttp = new XMLHttpRequest();
    
      let name = "";

     
      
      xmlHttp.open(
        "GET",
        `${shopUrl.url}web/api/v1/product_list_detail?size=20&name=${val}`,
        true
      ); // 异步处理返回
      xmlHttp.onreadystatechange = function callback() {
        if (xmlHttp.readyState === 4) {
          //4代表执行完成
          if (xmlHttp.status === 200) {
            //200代表执行成功
      
            let datas = xmlHttp.responseText
              ? JSON.parse(xmlHttp.responseText).data.rows
              : [];
         
            $this.vinoption = [];
            datas.forEach((v, i) => {
                
                 $this.vinoption.push({
                    value: v.id,
                    label: v.name,
                    link:v.link,
                    sku:v.sku,
                    model:v.model,
                    year:v.year
                });


              
            });
            $this.isLoading = false;
          }
        }
      };
      xmlHttp.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded;"
      );
      xmlHttp.send(null);
    }, 500),
        
    vinLink_search(accessory) {
        window.open('https://www.aodes.com/?year_id=&category=&s='+this.value+'&post_type=product&action=vpf-ymm-search','_blank');
      
    },
   
        // 搜索框跳转？
        vinLink() {
            this.$router.push({
                path: this.value,
                query: {
                    vin: this.Model
                }
            })
        },
        //菜单跳转到商城
        toshopping(url) { 
            console.log(url,'url');
            window.location.href = url;
            this.showmenu = false;
        },
        viewCart() {
            window.location.href = this.carlist[0].Viewcart
        },
        Checkout() {
            window.location.href = this.carlist[0].Checkout
        },
        carshow() {
            this.carmask = true
            console.log(this.carmask);
        },
        // account跳转
        accountlink(url, name) {
            if (name == 'Logout') {
                local.remove('userInfo')
                local.remove('token')
            }
            window.location.href = url
        },
        // 售后页面子菜单跳转
        link(type, url) {
            console.log(type);
            if (url==`${shopUrl.url}product-category/vehicle-all/`) {
                window.location.href = url
            }else{
                this.$router.push({
                path: url,
                query: {
                    s_state: type
                }
            })
            }

        },
        mouseenter(url, e) {
            if (url) {

                e.target.childNodes[0].src = url
            }
            e.target.classList.add('mouse')
        },
        mouseleave(url, e) {
            if (url) {
                e.target.childNodes[0].src = url
            }
            e.target.classList.remove('mouse')

        },
        // 删除购物车
        deleteItem(id, key) {
            this.loading = this.$loading({
                lock: true,
                text: 'Deleting',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 1)'
            });
            console.log('删除');
            let $this = this
            let user_id = local.get("userInfo") ? local.get("userInfo").id : '';
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("post", `${shopUrl.url}wp-json/api-aodes/v1/delete-cart`, true);// 异步处理返回
            xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
            xmlHttp.send(`user_id=${user_id}&product_id=${id}&cart_item_key=${key}`);
            xmlHttp.onreadystatechange = function callback() {
                if (xmlHttp.readyState === 4) {//4代表执行完成
                    if (xmlHttp.status === 200) {//200代表执行成功
                        $this.getcarInfo()
                        // $this.carlist = JSON.parse(xmlHttp.responseText).data
                        // $this.carnum = JSON.parse(xmlHttp.responseText).total
                        // $this.carlist.forEach(element => {
                        //     $this.price += Number(element.price*element.quantity)
                        // });

                        // console.log($this.carlist);
                    }
                }
            };
        },
        //购物车详情
        tocarDetail(url) {
            window.location.href = url
        },
        getcarInfo() {
            let $this = this
            let id = local.get("userInfo") ? local.get("userInfo").id : '';
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("post", `${shopUrl.url}wp-json/api-aodes/v1/user-cart`, true);// 异步处理返回
            xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
            xmlHttp.send(`user_id=${id}`);
            xmlHttp.onreadystatechange = function callback() {
                if (xmlHttp.readyState === 4) {//4代表执行完成
                    if (xmlHttp.status === 200) {//200代表执行成功
                        $this.carlist = JSON.parse(xmlHttp.responseText).data
                        $this.carnum = JSON.parse(xmlHttp.responseText).total
                        if ($this.carlist.length == 0) {
                            $this.price = 0
                        } else {
                            $this.price = 0
                            $this.carlist.forEach(element => {
                                $this.price += Number(element.price * element.quantity)
                            });
                        }
                        if ($this.loading) {
                            $this.loading.close();
                        }
                    }
                }
            };
        },
        async getMenu() {
            let $this = this
            let res = await $api_menuList()
            if (res.data.code == 200) {
                let data = res.data.data
                this.menuList = data.top_list
                this.options = []
                data.search_list[0].map(v => {
                    this.options.push({
                        label: v.title,
                        value: v.url
                    })
                })

            }
            //商城菜单
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", `${shopUrl.url}wp-json/api-aodes/v1/menu-api`, true);// 异步处理返回
            xmlHttp.onreadystatechange = function callback() {
                if (xmlHttp.readyState === 4) {//4代表执行完成
                    if (xmlHttp.status === 200) {//200代表执行成功
                        $this.shoopingMenu = JSON.parse(xmlHttp.responseText).data.part
                        $this.accountList = JSON.parse(xmlHttp.responseText).data.account.slice(1)
                    }
                }
            };
            xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;");
            xmlHttp.send(null);
        },
        open() {
            if (this.isopen) {
                this.account = []
                this.isopen = !this.isopen
            } else {
                this.isopen = !this.isopen
                console.log(this.accountList);
                this.account = this.accountList
            }

        }
    },
    created() {
        this.getMenu()
        this.getcarInfo()
    }
}
</script>

<style lang="less" scoped>
.red_tips{
    color: red;
}
.header {
    width: 100%;
    position: relative;

    .show {
        right: 0 !important;
    }

    .cardlog {
        position: absolute;
        width: 475px;
        padding: 1.8229vw 1.0417vw;
        padding-top: 3.125vw;
        background: #0f1423;
        right: -600px;
        top: 6.5vw;
        z-index: 999;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;

        .close {
            position: absolute;
            text-align: right;
            color: #fff;
            cursor: pointer;
            right: 1.0417vw;
            top: 1.0417vw;
        }

        .total {
            height: 3.3333vw;
            display: flex;
            justify-content: space-between;
            color: #fff;
            align-items: center;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            font-size: .8333vw;

            .right {
                font-weight: 700;
            }
        }

        ul {
            li {
                margin: 25px 0;
                display: flex;
                color: #fff;
                align-items: flex-end;
                justify-content: space-between;

                a {
                    color: #fff;
                }

                .icon {
                    width: 55px;
                    line-height: 85px;

                    cursor: pointer;

                    i {
                        background: #ed3333;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 12px;
                        padding: 3px;
                    }
                }

                .img {
                    margin-right: 1.5625vw;

                    img {
                        width: 85px;
                        height: 85px;
                        border-radius: .5208vw;
                    }
                }

                .info {
                    font-size: 14px;
                    line-height: 30px;
                    width: 170px;

                    h1 {
                        cursor: pointer;
                    }

                    h2 {
                        color: #ed3333;
                        line-height: 14px;
                        margin-top: 10px;
                    }
                }

                .number {
                    font-size: 14px;
                }
            }
        }

        .btn {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            .el-button {
                border: 0;
                font-size: 16px;
                width: 49%;
                height: 55px;
            }

        }
    }

    .top {
        max-width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 0 12px;

        a {
            width: 16%;
            margin-right: .2604vw;
            display: inline-block;

            @media screen and (max-width: 1400px) {
                width: 20%;
            }
        }
        .serchs{
            width: 41%;
            position: relative;
          
            .serch1{
                width: 100%;
                display: flex;
            }
            .drops{
                position: absolute;
                top: 1.9792vw !important;
                left: 0px;
                z-index: 9999;
                background-color: #fff;
                width: 84%;
                padding-top: 20px;
                line-height: 30px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
                cursor: pointer;
            }
            .drops li:hover {
                background-color: #F3F4F6;
                
            }
            .drops li{
                height: 40px;
                line-height: 40px;
                padding-left: 10px;
                color: #ADAEB0 !important;
            }

     

            
        }
        .serch {
            width: 41%;
            display: flex;
        }
        .three{
            width: 90%;
            margin-right: 20px;
        }

        .logo {
            max-width: 236px;
            width: 13.5417vw;
            height: auto;
            padding: 5px;
        }

        .items {
            width: 39%;
            display: flex;
            justify-content: flex-end;
            padding-right: 16px;
            height: 2.4896vw;
        }

        .one {
            width: 17.6042vw;
            margin-right: .5729vw;
            height: 2vw;
            background: #F3F4F6;
            margin-top: -0.2604vw;

        }

        .two {
            width: 11.7708vw;
            margin-right: 15px;
            height: 1.9792vw;
            background: #F3F4F6;
            margin-top: -0.2604vw;
        }

        .el-button {
            width: 100px;
            height: 1.9792vw;
            border-radius: 4px;
            padding: 0 .5208vw;
            font-size: .625vw;
            border: 1px solid #ADAEB0;
            color: #ADAEB0;
            //margin-top: -0.2604vw;
        }

        .item {
            letter-spacing: 0.02rem;
            padding: 0 11px;
            height: 2.4896vw;
            background: #0F1423;
            margin-left: 20px;
            color: #fff;
            transform: skewX(-10deg);
            position: relative;
            font-size: 0.8vw;
            cursor: pointer;
            white-space: nowrap;

            @media screen and (max-width: 1570px) {
                margin-left: 10px;
            }

            @media screen and (max-width: 1440px) {
                margin-left: 8px;
            }

            div {
                transform: skewX(10deg);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-weight: 600;

                .tag {
                    position: relative;
                    transform: skewX(-10deg);
                    width: auto;
                    height: auto;

                    span {
                        transform: skewX(10deg);
                        display: block;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        background: #ED3334;
                        color: #fff;
                        position: absolute;
                        border-radius: 50%;
                        right: -8px;
                        top: -9px;
                        font-size: .7292vw;
                        font-weight: 600;
                    }

                    img {
                        transform: skewX(10deg);
                        width: auto;
                        height: 1.6vw;
                        margin: 0 .2604vw;
                    }
                }

            }

        }

        .account {
            position: relative;
            z-index: 999;
            padding-right: 1.5625vw;

            img {
                width: 1.1vw;
            }

            ul {
                width: 100%;
                position: absolute;
                background: #0F1423;
                height: 2px;
                top: calc(2.4896vw + 2px);
                z-index: 999;
                left: 0;
                right: 0;
                margin: 0 auto;
                overflow: hidden;
                -webkit-transition: 0.3s;
                -moz-transition: 0.3s;
                -ms-transition: 0.3s;
                -o-transition: 0.3s;
                transition: 0.3s;

                li {
                    padding-left: .6771vw;
                    font-size: .8333vw;
                    line-height: 2.0833vw;

                    img {
                        height: .7292vw;
                        width: auto;
                        margin-right: .5208vw;
                    }
                }

                li:hover {
                    background: #ED3333;
                }
            }
        }

        .account:active ul {
            height: 10.4167vw;
        }
    }

    /deep/.el-icon-search:before {
        display: none;
    }

    /deep/.el-input__suffix {
        right: -4px;
    }

    /deep/.el-input--small .el-input__inner {
        height: 2.4896vw !important;
        background: #F3F4F6;
        padding-left: 9px;
        font-size: 0.85vw;
        color: #ADAEB0 !important;
    }

    /deep/.el-icon-arrow-up:before {
        content: "\e78f";
    }

    /deep/.el-input--small .el-input__icon {
        line-height: 1.9792vw;
    }

    .menu-box {
        width: 100%;
        height: 2.9167vw !important;
        margin-top: 12px;
        display: flex;
        line-height: 2.8646vw;

        .left {
            width: 19.6vw;
            padding-left: 6.25vw;
            background: #CDCDCD;
            font-size: 1.15vw;
            font-weight: 700;
            margin-right: 3px;
            color: #0F1423;
            cursor: pointer;
            line-height: 2.9167vw;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                vertical-align: -4px;
            }
        }

        // 菜单
        .link-list {
            display: flex;
            z-index: 99;

            li {
                padding: 0 1.3vw 0 0.5vw;
                background: #EBEBEB;
                margin-right: 3px;
                cursor: pointer;
                position: relative;
                color: #0F1423;
                font-size: .8333vw;
                align-items: center;
                z-index: 99;
                -webkit-transition: 0.3s;
                -moz-transition: 0.3s;
                -ms-transition: 0.3s;
                -o-transition: 0.3s;
                transition: 0.3s;

                img {
                    width: auto;
                    height: .8854vw;
                    min-height: 14px;
                    margin-right: 0.5vw;
                    vertical-align: -2px;
                }

                i {
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #818181;
                }

                ul {
                    padding-top: 4px;
                    display: none;
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    min-width: 100%;
                    width: auto;
                    top: 2.864vw;
                    z-index: 99;
                    -webkit-transition: 0.3s;
                    -moz-transition: 0.3s;
                    -ms-transition: 0.3s;
                    -o-transition: 0.3s;
                    transition: 0.3s;

                    li {
                        min-width: 100%;
                        line-height: 2.1354vw;
                        font-size: .8333vw;
                        color: #0F1423;
                        padding: 0 20px;
                        white-space: nowrap;
                        position: relative;
                        z-index: 99;

                        ul {
                            padding-left: 3px;
                            left: 100%;
                            position: absolute;
                            top: -4px;
                            z-index: 99;
                        }
                    }
                }

            }
        }

        .right {
            flex: 1;
            height: 2.9167vw;
            background: #CDCDCD;
        }

        li:hover {
            background: #6A6A6A;
            color: #fff !important;
        }

        li:hover>i {
            color: #fff;
        }

        li:hover>ul {
            display: block;
            opacity: 1;
        }

        li:hover span {
            color: #fff;
        }
    }
}

.mouse>ul {
    animation: shows 0.8s;
}

@keyframes shows {
    0% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block !important;
        opacity: 1 !important;
    }
}
.header-moblie{
    display: none;
}

@media (max-width: 768px) {
    .showless{
        display: block;
        width: 100%;
        text-align: center;
        position: fixed;
        bottom: 25px;
        color: #a7a4a4;
    }
    .header-moblie{
        display: block;
    }
    .menu-nav-mobile{
        z-index: 99999;
        top: 47px;
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    } 
    .show{
        display: block !important;
    }
    .icon_s{
        width: 100%;
        height: 44px;
        background-color: #000;
        /* padding: 20px; */
        display: flex;
        align-items: center;
        justify-content: left;
        padding-left: 10px;
        font-size: 28px;
        position: fixed;
        top: 0px;
        gap: 10px;
        color: #fff;
        z-index: 9999;
    }
    .right_icons{
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        right: 10px
    }
    .carlist_length{
        background: #ED3333;
        border-radius: 50%;
        color: var(--color-white);
        position: absolute;
        width: 20px;
        height: 21px;
        line-height: 21px !important;
        font-size: 11px;
        text-align: center;
        right: -8px;
        top: -9px;

    }
    .item{
     
        font-size: 1rem;
    }
    .header-moblie .item .item1{
        display: flex;
       
    }
    .header{
        display: none;
    }

    .cardlog {
        position: fixed;
        width: 95%;
        padding: 1.8229vw 1.0417vw;
        background: #0f1423;
        right: 2.5%;
        top: 55px;
        z-index: 999;
        transition: 0.3s;
        padding-bottom: 15px;
        padding-top: 0px;
        height: 80vh;
        opacity: 0.97;
        display: none;
        .close {
            position: absolute;
            text-align: right;
            color: #fff;
            cursor: pointer;
            right: 3.0417vw;
            top: 1.0417vw;
        }

        .total {
            height: 3.3333vw;
            display: flex;
            justify-content: space-between;
            color: #fff;
            align-items: center;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            font-size: 1rem;
            margin-top: 23px;
            padding-top: 20px;
            padding-bottom: 20px;
            .right {
                font-weight: 700;
            }
        }

        ul {
            max-height: 81%;
             overflow-y: scroll;
            li {
                margin: 0px 0;
                display: flex;
                color: #fff;
                align-items: flex-end;
                justify-content: flex-start;

                a {
                    color: #fff;
                }

                .icon {
                    width: 42px;
                    line-height: 77px;

                    cursor: pointer;

                    i {
                        background: #ed3333;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 10px;
                        padding: 2px;
                    }
                }

                .img {
                    // margin-right: 1.5625vw;

                    img {
                        width: 3rem;
                        height: 3rem;
                        object-fit: cover;
                        border-radius: .5208vw;
                        margin-right: 28px;
                    }
                }

                .info {
                    font-size: 12px;
                    line-height: 30px;
                    width: 90%;

                    h1 {
                        cursor: pointer;
                    }

                    h2 {
                        color: #ed3333;
                        line-height: 14px;
                        margin-top: 5px;
                    }
                }

                .number {
                    font-size: 12px;
                    width: 25%;
                }
            }
        }

        .btn {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;

            .el-button {
                border: 0;
                font-size: 12px;
                width: 49%;
                // height: 55px;
            }

        }
    }

}
</style>